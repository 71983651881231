import { Box, Flex, HStack, Heading, Link, Spacer } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import AdSense from './ad-sense';
import ThemeToggle from './toggle-theme';

function NavBar({ siteTitle, ...rest }) {
  return (
    <>
      <Box as="head">
        <AdSense adClient="ca-pub-4453193920935160" />
      </Box>
      <Flex as="header" {...rest}>
        <Heading>
          <Link as={GatsbyLink} to="/" _hover={{ textDecor: 'none' }}>
            {siteTitle}
          </Link>
        </Heading>
        <Spacer />
        <HStack spacing={4}>
          {/* <Link as={GatsbyLink} to="/help">Help</Link> */}
          <Link as={GatsbyLink} to="/" _hover={{ textDecor: 'none' }}>
            Home
          </Link>
          <Link as={GatsbyLink} to="/about" _hover={{ textDecor: 'none' }}>
            About
          </Link>
          <ThemeToggle />
        </HStack>
      </Flex>
    </>
  );
}

NavBar.propTypes = {
  siteTitle: PropTypes.string
};

NavBar.defaultProps = {
  siteTitle: ``
};

export default NavBar;
