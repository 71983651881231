import React, { useEffect } from 'react';

const AdSense = ({ adClient }) => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client={adClient}
        data-ad-slot="YOUR_AD_SLOT_ID"
        data-ad-format="auto"
      ></ins>
    </div>
  );
};

export default AdSense;
